import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Gesture from "@material-ui/icons/Gesture";
import Build from "@material-ui/icons/Build";
import BorderColorIcon from '@material-ui/icons/BorderColor';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import servicesStyle from "assets/jss/styles/pages/landing/servicesStyle.js";

const useStyles = makeStyles(servicesStyle);

export default function SectionServicesLanding() {
  const classes = useStyles();
  return (
    <div className={classes.services}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto
          )}
        >
          <h2 className={classes.title}>
            <i>"Ventilar, ventilar y ventilar"</i>, el consejo de los expertos para evitar el contagio en lugares cerrados
          </h2>
          <p className={classes.description}>
            <b>Se acerca el invierno</b> y cerramos las ventanas para que no se escape el calor. Ahora es más necesario que nunca una adecuada ventilación o filtrado del aire para minimizar los riesgos de contagio.
          </p>
          <p className={classes.description}>
            Durante una pandemia, se estima que la tasa debe ser mayor. Un estudio de 2016 señaló que un <b>cambio de aire de nueve veces por hora</b> redujo la transmisión de los virus de SARS, MERS y H1N1 en un hospital de Hong Kong.
          </p>
          <p className={classes.description}>
            Tanto la Organización Mundial de la Salud como el Centro de Control de Enfermedades de Estados Unidos, CDC, señalan que <b>la mala ventilación aumenta el riesgo de transmisión.</b>.
          </p>

          <br />
          <br />

          <h6><a 
            href="https://www.miteco.gob.es/es/ministerio/medidas-covid19/sistemas-climatizacion-ventilacion/default.aspx" 
            target="_blank" 
            rel="noreferrer noopener">Mira las recomendaciones del Ministerio de sanidad</a></h6>
        </GridItem>
      </GridContainer>
    </div>
  );
}
