import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import Subject from '@material-ui/icons/Subject'


import RestaurantIcon from '@material-ui/icons/Restaurant';
import BusinessIcon from '@material-ui/icons/Business';
import InfoArea from "components/InfoArea/InfoArea.js";

import Cargador from "assets/img/sections/Extractor.jpg";

import featuresStyle from "assets/jss/styles/pages/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

const Ventilacion = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <GridContainer>
        <div className={classes.features4}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Extracción de aire</h2>
              <h5 className={classes.description}>
                Una adecuada renovación de aire es esencial para frenar los contagios víricos. 
                Instalamos sistemas con las adecuadas renovaciones/hora, con bocas situadas estratégicamente para minimizar la expansión de los aerosoles.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={3} className={classes.mlAuto}>
              <InfoArea
                icon={RestaurantIcon}
                title="Ocio y Hostelería"
                description="Protege a tus clientes con una adecuada ventilación. Los sistemas de aire acondicionado forman un circuito cerrado y ayudan a expandir los virus. Por eso es necesario acomprañar de una renovación de aire o filtrado."
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={4}>
              <div className={classes.phoneContainer} style={{marginTop: "60px"}}>
                <img src={Cargador} alt="..." />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={3} className={classes.mrAuto}>
              <InfoArea
                icon={BusinessIcon}
                title="Oficinas y Negocios"
                description="Un empleado contagiado puede poner en peligro a toda la oficina. Salva tu negocio y empleados con una adecuada ventilación y una inversión mínima."
                iconColor="primary"
              />
            </GridItem>
          </GridContainer>
        </div>

      </GridContainer>
    </div>
  )
}

export default Ventilacion
