import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import Button from 'components/CustomButtons/Button.js'
import Subject from '@material-ui/icons/Subject'


import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import BusinessIcon from '@material-ui/icons/Business';
import InfoArea from "components/InfoArea/InfoArea.js";

import FiltroImg from "assets/img/sections/Filtro.png";

import featuresStyle from "assets/jss/styles/pages/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

const Filtro = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <GridContainer>
        <div className={classes.features4}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Purificación de aire</h2>
              <h5 className={classes.description}>
                Cada vez somos más conscientes de la importancia de respirar aire limpio y puro, la mala calidad de aire respirado afecta de forma importante a la salud de las personas y su capacidad de rendimiento, siendo el cansancio y problemas respiratorios entre los síntomas más habituales.
              </h5>
              <h5 className={classes.description}>
                La limpieza y purificación se realiza a través de la captación de partículas con diferentes etapas de filtración y mediante el tratamiento del aire con cámaras germicidas de lámparas ultravioletas UVc o plasma iónico según modelos elegidos.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={3} className={classes.mlAuto}>
              <InfoArea
                icon={BatteryChargingFullIcon}
                title="AUMENTO DE PRODUCTIVIDAD"
                description="Estudios demuestran que a mejor calidad de aire mejor es nuestro rendimiento, aumentando la eficiencia de nuestras acciones diarias a base de la mejora en la oxigenación de la sangre."
                iconColor="info"
              />
              <InfoArea
                icon={RestaurantIcon}
                title="HOSTELERÍA Y LOCALES DE OCIO"
                description="Instalamos purificadores de aire silenciosos para que sus clientes puedan concentrarse y disfrutar en sus instalaciones con aire limpio y saludable."
                iconColor="primary"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={4}>
              <div className={classes.phoneContainer}>
                <img src={FiltroImg} alt="..." />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={3} className={classes.mrAuto}>
              <InfoArea
                icon={FavoriteBorderIcon}
                title="MEJORAR LA SALUD"
                description="Respirando aire limpio se reduce el número de problemas respiratorios y por cansancio. La buena calidad del aire, además de aumentar la esperanza de vida, mejora el desarrollo mental y físico consiguiendo una mejora de la salud."
                iconColor="danger"
              />              
              <InfoArea
                icon={BusinessIcon}
                title="OFICINAS Y CENTROS EDUCATIVOS"
                description="Nuestras soluciones capturan las partículas nocivas que crean alergias y eliminan gérmenes, bacterias y virus para obtener una óptima y saludable calidad de aire."
                iconColor="success"
              />
            </GridItem>
          </GridContainer>
        </div>

      </GridContainer>
    </div>
  )
}

export default Filtro
